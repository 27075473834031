import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.VUE_APP_API_URL}vehicle/`;

class VehicleService {
  getVehicle(client, registration) {
    return axios.get(
      `https://api.varsanpr.com/api/vehicle/${registration}?client_id=${client}`,
      { headers: authHeader() }
    );
  }

  getVehicleNotes(client, vehicle_id) {
    return axios.get(
      `https://api.varsanpr.com/api/vehicle/${vehicle_id}/notes?client_id=${client}`,
      { headers: authHeader() }
    );
  }

  putVehicleNote(client, vehicle_id, note) {
    return axios.put(
      `https://api.varsanpr.com/api/vehicle/${vehicle_id}/notes?client_id=${client}`,
      {
        note: note,
      },
      { headers: authHeader() }
    );
  }

  getCustomerServiceMessage(client, vehicle_id) {
    return axios.get(
      `https://api.varsanpr.com/api/vehicle/${vehicle_id}/message?client_id=${client}`,
      { headers: authHeader() }
    );
  }

  putCustomerServiceMessage(client, vehicle_id, message) {
    return axios.put(
      `https://api.varsanpr.com/api/vehicle/${vehicle_id}/csm?client_id=${client}`,
      { message },
      { headers: authHeader() }
    );
  }

  deleteCustomerServiceMessage(client, vehicle_id) {
    return axios.delete(
      `https://api.varsanpr.com/api/vehicle/${vehicle_id}/csm?client_id=${client}`,
      { headers: authHeader() }
    );
  }

  unblacklistVehicle(client, registration) {
    return axios.delete(`https://api.varsanpr.com/api/blacklist`, {
      data: {
        client_id: client,
        registration: registration,
      },
      headers: authHeader(),
    });
  }

  blacklistVehicle(client, registration, reason) {
    return axios.put(
      `https://api.varsanpr.com/api/blacklist`,
      {
        client_id: client,
        registration: registration,
        reason: reason,
      },
      { headers: authHeader() }
    );
  }
}

export default new VehicleService();
